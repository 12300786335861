<template>
    <div :class="['detail', isMobile()?'mb-content':'']">
        <div class="title">
            {{detail.title}}
            <p v-if="isMobile" class="time">{{detail.createTime}}</p>
        </div>
        <div v-html="detail.content" class="detail-text"></div>
    </div>
</template>
<script>
import { ref } from '@vue/reactivity'
export default {
    setup() {
        document.body.scrollTop = 0
        const detail = ref('')

        // 判断浏览器函数
        const isMobile = () => {
            if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
                return true; // 移动端
            }else{
                return false; // PC端
            }
        }

        detail.value = JSON.parse(sessionStorage.getItem('detail'))
        detail.value.content = detail.value.content.replace(
            /\/ueditor/g,
            "http://ueditor.aixuesheng.net/ueditor"
        );
        
        return {
            detail,
            isMobile
        }
    },
}
</script>
<style lang="scss">
    .detail-text {
        font-size: 0.28rem !important;
        color: #333;
        img {
            width: 100%;
        }
    }
</style>
<style lang="scss" scoped>
    .detail {
        margin: 25px 10px;
        .title {
            font-size: 26px;
            padding-bottom: 20px;
            border-bottom: 1px solid #e1e1e1;
        }
    }
    .mb-content {
        margin-bottom: 2rem;
        .title {
            font-size: 0.32rem;
            padding-bottom: 0.2rem;
            border-bottom: none;
        }
        .time{ 
            font-size: 0.28rem;
            color: #999;
            margin-top: 0.2rem;
        }
    }
</style>